import React, { Component } from 'react';
import {Router, navigate} from '@reach/router';
import {Helmet} from 'react-helmet';
import firebase from './Firebase.js';
import Register from './Register.js';
import AppHeader from './AppHeader.js';
import PageHome from './PageHome.js';
import PageWork from './PageWork.js';
import PageBlog from './PageBlog.js';
import PageAbout from './PageAbout.js';
import PageContact from './PageContact.js';
import PageResources from './PageResources.js';
import PageLogin from './PageLogin.js';
import AppFooter from './AppFooter.js';
import Welcome from './Welcome.js';
import PageMeetings from './PageMeetings.js';
import Nodelist from './Nodelist.js';
import Demo from './Demo.js';
import Demo2 from './Demo2.js';
import EssayForm from './EssayForm.js';
import './App.css';
import './Absent-horn-grid.css';
import './rwd.css';


class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      displayName: null,
      userID: null,
      user: null
    };
  }

  componentDidMount(){
 
    firebase.auth().onAuthStateChanged(FBUser => {

      if(FBUser) {
        this.setState({
          user: FBUser,
          displayName: FBUser.displayName,
          userID: FBUser.uid
        });



        const meetingsRef = firebase
                            .database()
                            .ref('meetings/' + FBUser.uid);

        meetingsRef.on('value', snapshot => {

          let meetings = snapshot.val();
          let meetingsList = [];

          for(let item in meetings){
            meetingsList.push({

              meetingID: item,
              meetingName: meetings[item].meetingName

            });
          }

          this.setState({
            meetings:  meetingsList,
            howManyMeetings: meetingsList.length
          });

        });

        } else {
          this.setState({user: null})
        }

      });

    }

/* ////////////////////////////////////////////// */

/*
const blogPostRef = firebase
      .database()
      .ref('blogposts/' + FBUser.uid);

        blogPostRef.on('value', snapshot => {

          let blogPosts = snapshot.val();
          let blogPostList = [];

          for(let item in blogPosts){
            blogPostList.push({

              blogPostID: item,
              blogPostName: blogPosts[item].blogPostName

            });
          }

          this.setState({
            blogPosts:  blogPostList,
            howManyBlogPosts: blogPostList.length
          });

        });

        } else {
          this.setState({user: null})
        }
    });

  }
   */     
       

/*////////////////////////////////////////////////////* */



registerUser = userName => {

  firebase.auth().onAuthStateChanged(FBUser => {

    FBUser.updateProfile({
      displayName: userName
    })
    .then(() => {
      this.setState({
        user: FBUser,
        displayName: FBUser.displayName,
        userID: FBUser.uid
      });
      navigate('/Contact');
    });
  });
}

logoutUser = (e) => {

  e.preventDefault();
  this.setState({
    displayName: null,
    userID: null,
    user: null
  });

  firebase
    .auth()
    .signOut()
    .then(() => {
      navigate('/Login');
    });
};

addMeeeting = meetingName => {
  const ref = firebase
  .database()
  .ref(`meetings/${this.state.user.uid}`);
  ref.push({meetingName: meetingName});
}


addBlogPost = blogPostName => {
  const ref = firebase
  .database()
  .ref(`blogposts/${this.state.user.uid}`);
  ref.push({blogPostName: blogPostName});
}

  render() {
    return (
      <div className="App">

      <Helmet>
        {/* Site validation */}
        <meta name="google-site-verification" content="" />
        <meta name="msvalidate.01" content="" />

        {/* GEO location */}
        <meta name="geo.region" content="DK-82" />
        <meta name="geo.placename" content="Ebeltoft" />
        <meta name="geo.position" content="56.231152;10.599199" />
        <meta name="ICBM" content="56.231152, 10.599199" />

        {/* SEO */}
        <link rel="canonical" href="https://torbencolding.dk" />       
      </Helmet>
 



        {/* App Header */}
        <AppHeader userName={this.state.displayName} />

          {/* Only show if user exists */}
        
        
       
        {this.state.displayName && (
          <Welcome userName={this.state.displayName} logoutUser={this.logoutUser}/>    
        )}
 
        
        <Router>
          <PageHome path="/" user={this.state.userName}/>
          <PageWork path="/Work" user={this.state.userName}/>
          <PageBlog 
            path="/Blog" 
            user={this.state.userName}
            blogPosts={this.state.blogPosts} 
            addBlogPost={this.addBlogPost}
            userID ={this.state.userID}
            
            />
          <PageAbout path="/About" user={this.state.userName}/>
          <PageContact path="/Contact" user={this.state.userName}/>
          <PageResources path="/Resources" user={this.state.userName}/>
          <PageLogin path="/Login" user={this.state.userName}/>
          <Register path="/Register" registerUser={this.registerUser}/>
          <PageMeetings 
            path="/Meetings" 
            meetings={this.state.meetings} 
            addMeeting={this.addMeeting}
            userID ={this.state.userID}
          />
          <Demo path="/Demo" user={this.state.userName}/>
          <Demo2 path="/Demo2" user={this.state.userName}/>
          <EssayForm path="/EssayForm" user={this.state.userName}/>
          

        </Router>

        <AppFooter />
      </div>
    );
  }
}

export default App;
