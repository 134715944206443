import React, { Component } from 'react';
import firebase from './Firebase.js';



class MeetingsList extends Component {

    constructor(props){
        super(props);
        this.deleteMeeting = this.deleteMeeting.bind(this);
    }
 
    deleteMeeting = (e, whichMeeting) => {
        e.preventDefault();
        const ref = firebase
        .database()
        .ref(`meetings/${this.props.userID}/${whichMeeting}`)
        ref.remove();
    }

  render(props) {

    const {meetings} = this.props;
    const myMeetings = meetings.map(item => {

       return(

            <li className="meeting_item" key={item.meetingID}>
                {item.meetingName} 
                <span onClick={(e) => this.deleteMeeting(e, item.meetingID)}> X </span>
            </li>
        );
    });

    return (
        <ul className="meeting-list">
            {myMeetings}
        </ul> 
    );
  }
}

export default MeetingsList;