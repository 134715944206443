import React, { Component } from 'react';
import { MdMenu } from 'react-icons/md';
import './AppNavigation.css'; 
import {Link} from '@reach/router';
import AppLogo from './AppLogo.js';



class AppNavigation extends Component {

    constructor(props) {
      super(props);
      this.state = {isToggleOn: false};
  
      // This binding is necessary to make `this` work in the callback
      this.handleClick = this.handleClick.bind(this);
    }
  
   
    handleClick() {
      this.setState(state => ({
        isToggleOn: !state.isToggleOn
      }));
    }

    render() {

        // Assign this.props to user object
        const {user} = this.props;

        // Add menu labels
        const pages = [
            {id: 1, name: 'Home', path: ''}, 
            {id: 2, name: 'Blog', path: 'Blog'},
            {id: 3, name: 'Work', path: 'Work'},
            {id: 4, name: 'Resources', path: 'Resources'}, 
            {id: 5, name: 'About', path: 'About'}, 
            {id: 6, name: 'Contact', path: 'Contact'}
        ];
       
        // Create menu items 
        const navItems = pages.map((page) =>      
            <li key={page.id} id={'App-navigation__' + page.name.toLowerCase()} className="App-navigation__menu-item" onClick={this.handleClick}>
                <Link to={"/" + page.path}>{page.name}</Link>
            </li>
        );

      return (
        <nav className="App-navigation">
        {this.state.isToggleOn ? <div className="App-navigation__overlay" onClick={this.handleClick}></div> : ''}
        
          <button className="App-navigation__button" onClick={this.handleClick}>
              <MdMenu />
          </button>

          <div id={this.state.isToggleOn ? 'App-navigation__menu--open' : 'App-navigation__menu--close'}  className="App-navigation__content" >

              <header className="App-navigation__header">
                  <h3 className="App-navigation__title">Hovedmenu</h3>
              </header> 
          
              <ul className="App-navigation__menu"> 
                  {/* Aditional list items generated from map() above */}
                  {navItems} 

                  {/* Only show if user is not logged in */}
                  {!user && (
                      <li key={100} className="App-navigation__menu-item" onClick={this.handleClick}>
                          <Link to="/Login">Login</Link>
                      </li>
                  )}        
              </ul> 

              {/* SITE FOOTER */} 
              <footer className="App-navigation__footer">
            
                  {/* APP LOGO */}     
                  <AppLogo />
              
                  {/* <!-- SITE ADDRESS --> */}
              
                  <address className="app-address">Hovedgaden 9<br />
                  Lyngsbæk<br />
                  8400 Ebeltoft<br />
                  mail@torbencolding.dk</address>
              </footer>
          </div>
        </nav>    
      );
    }
  }

  export default AppNavigation;