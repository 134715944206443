import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import FormError from './FormError.js';
import BlogPostList from './BlogPostList.js';
import './PageBlog.css';


class PageBlog extends Component {

    constructor(props){
        super(props);
    
        // Capture values from form fields
        this.state = {
            blogPostName: ''
        }
    
        // Bind 'this' to make it work with regular functions (handleChange())
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleChange(e) {
        const itemName = e.target.name;
        const itemValue = e.target.value;
    
        this.setState({[itemName]: itemValue});
    }
    
    handleSubmit(e){
        
        e.preventDefault();
        this.props.addBlogPost(this.state.blogPostName);
        this.setState({blogPostName: ''});
    }



  render() {

 //   const {user} = this.props;

    const styles = {
        backgroundColor: 'none'
    }

    return (
        <main className="App-content" style={styles}>  

            <Helmet>
                {/* Page info */}
                <title>Torben Colding | Blog</title>
                <meta name="description" content="My blog..." />

                {/* Social Media */}  
                <meta property="og:locale" content="da_DK" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Torben Colding | Personal Website" />
                <meta property="og:url" content="https://torbencolding.dk" />
                <meta property="og:site_name" content="Torben Colding" />
                <meta property="og:description" content="..." />
                <meta property="og:image" content="https://torbencolding.dk/img/x.jpg" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Login | Torben Colding" />
                <body className="page-blog" />
            </Helmet>  

        

            <section className="App-section-outer">
                <div className="App-section-inner">
                    <div className="meeting">

                        {/* Page title*/}
                        <h1>Blog</h1>

                        {/* Form submission for meetings */}   
                        <form onSubmit={this.handleSubmit} >   
                            {this.state.errorMessage !== null ? (
                                <FormError theMessage={this.state.errorMessage}/>
                            ) : null}

                            {/* HTML form elements*/}
                            <div className="field-group">
                                <div className="field">
                                    <input name="blogPostName" id="blogPostName"  type="text"  value={this.state.blogPostName} onChange={this.handleChange} placeholder="Post name" required />
                                </div>
                            </div>

                            <div className="field-group">
                                <div className="field">
                                    <button> + </button>
                                </div>
                            </div>
                        </form>

                        {/* Meeting list from database */}            
                        <div className="meetings">

                            {this.props.blogPosts && this.props.blogPosts.length ? (
                                <h4 className="component-title">Latest blog posts</h4>
                            ) : null}


                            {this.props.blogPosts && (
                                <BlogPostList 
                                    blogPosts={this.props.blogPosts} 
                                    userID ={this.props.userID} 
                                />
                            )}
                        </div>
                    </div>    
                </div>
            </section>

        </main>
    );
  }
}

export default PageBlog;
