 
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

// Initialize Firebase
const config = {
    apiKey: "AIzaSyDfnzSQtlcCeLoaKIcE7HGPPNTbBsD0gUA",
    authDomain: "torbencolding-7e023.firebaseapp.com",
    databaseURL: "https://torbencolding-7e023.firebaseio.com",
    projectId: "torbencolding-7e023",
    storageBucket: "torbencolding-7e023.appspot.com",
    messagingSenderId: "685888757298"
  };
  firebase.initializeApp(config);

  export const provider = new firebase.auth.GoogleAuthProvider();
  export const auth = firebase.auth();
  export default firebase; 

  