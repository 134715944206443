import React, { Component } from 'react';
import FormError from './FormError.js';
import firebase from './Firebase.js';
import {navigate} from '@reach/router';
import {Helmet} from 'react-helmet';

class PageLogin extends Component {

  constructor(props){
    super(props);

    // Capture values from form fields
    this.state = {
        email: '',
        password: '',
        errorMessage: null
    }

    // Bind 'this' to make it work with regular functions (handleChange())
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
}

handleChange(e) {
    const itemName = e.target.name;
    const itemValue = e.target.value;

    this.setState({[itemName]: itemValue});
}

handleSubmit(e){
    var registrationInfo = {
        email: this.state.email,
        password: this.state.password
    }
    e.preventDefault();

    firebase.auth().signInWithEmailAndPassword(
       registrationInfo.email,
       registrationInfo.password 
    )
    .then(() => {
      navigate('/'); // Where to send user after login
    })
    .catch(error => {
        if(error.message !== null){
            this.setState({errorMessage: error.message});
        } else {
            this.setState({errorMessage: null});
        }
    });
}


  render() {

  //  const {user} = this.props;

    const styles = {
        backgroundColor: 'none'
    }

    return (
        <main className="App-content" style={styles}>  

            <Helmet>
                {/* Page info */}
                <title>Torben Colding | Login</title>
                <meta name="description" content="login..." />

                {/* Social Media */}  
                <meta property="og:locale" content="da_DK" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Torben Colding | Personal Website" />
                <meta property="og:url" content="https://torbencolding.dk" />
                <meta property="og:site_name" content="Torben Colding" />
                <meta property="og:description" content="..." />
                <meta property="og:image" content="https://torbencolding.dk/img/x.jpg" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Login | Torben Colding" />
                <body className="page-login" />
            </Helmet> 

            <h1>Login</h1>

            <form onSubmit={this.handleSubmit}>   
                {this.state.errorMessage !== null ? (
                    <FormError theMessage={this.state.errorMessage}/>
                ) : null}

            <div className="field-group">
                <label htmlFor="email" className="label">Email:</label>
                <div className="field">
                    <input name="email" id="email" type="email" spellCheck="false"  value={this.state.email} onChange={this.handleChange} placeholder="Email Address" required /> 
                </div>
            </div>

            <div className="field-group">
                <label htmlFor="password" className="label">Password:</label>
                <div className="field">
                    <input name="password" id="password"  type="password"  value={this.state.password} onChange={this.handleChange} placeholder="Password" required />
                </div>
            </div>

            <div className="field-group">
                <div className="field">
                    <button>Login</button>
                </div>
            </div>

          </form>





        </main>
    );
  }
}

export default PageLogin;
