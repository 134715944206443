import React, { Component } from 'react';
import FormError from './FormError.js';
import MeetingsList from './MeetingsList.js';
import './PageMeetings.css';

class PageMeetings extends Component {

    constructor(props){
        super(props);
    
        // Capture values from form fields
        this.state = {
            meetingName: ''
        }
    
        // Bind 'this' to make it work with regular functions (handleChange())
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleChange(e) {
        const itemName = e.target.name;
        const itemValue = e.target.value;
    
        this.setState({[itemName]: itemValue});
    }
    
    handleSubmit(e){
        
        e.preventDefault();
    
        this.props.addMeeting(this.state.meetingName);
        this.setState({meetingName: ''});
    }
    
  render(props) {

    return (

    <section className="App-section-outer">
        <div className="App-section-inner">
            <div className="meeting">

                {/* Page title*/}
                <h1>Meetings</h1>

                 {/* Form submission for meetings */}   
                <form onSubmit={this.handleSubmit} >   
                    {this.state.errorMessage !== null ? (
                        <FormError theMessage={this.state.errorMessage}/>
                    ) : null}

                    {/* HTML form elements*/}
                    <div className="field-group">
                        <div className="field">
                            <input name="meetingName" id="meetingName"  type="text"  value={this.state.meetingName} onChange={this.handleChange} placeholder="Meeting name" required />
                        </div>
                    </div>

                    <div className="field-group">
                        <div className="field">
                            <button> + </button>
                        </div>
                    </div>
                </form>

                {/* Meeting list from database */}            
                <div className="meetings">

                    {this.props.meetings && this.props.meetings.length ? (
                        <h4 className="component-title">Meetings coming up</h4>
                    ) : null}


                    {this.props.meetings && (
                        <MeetingsList 
                            meetings={this.props.meetings} 
                            userID ={this.props.userID} 
                        />
                    )}
                </div>
            </div>    
        </div>
    </section>
    );
  }
}

export default PageMeetings;