import React, { Component } from 'react';
import './BlockCodepen.css';
import CodepenLogo from './logo-symbol-text-codepen.png';
import CssAnimatedClock from './codepen-thumbs/css-animated-clock.mp4';
import CssAnimatedBalls from './codepen-thumbs/codepen-animated-balls.mp4';


class BlockCodepen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      animatedClock: 'https://codepen.io/torbencolding/pen/oqdpRX',
      animatedBeer: 'https://codepen.io/torbencolding/pen/yzPjrE',
      flexboxAutoMargin: 'https://codepen.io/torbencolding/pen/NppNRL',
      cssGridlayouts: 'https://codepen.io/torbencolding/pen/EZKqwB',
      animatedSvg: 'https://codepen.io/torbencolding/pen/LyWYdE',
      conditionalStyling: 'https://codepen.io/torbencolding/pen/oGVaYV',
      animatedSvgLogo: 'https://codepen.io/torbencolding/pen/aByyOK',
      flexboxAlignment: 'https://codepen.io/torbencolding/pen/rajRXW',
      responsivePagination: 'https://codepen.io/torbencolding/pen/VKXawm',
      cssTabset: 'https://codepen.io/torbencolding/pen/WbZeYr',
      responsiveFilmstrip: 'https://codepen.io/torbencolding/pen/eQKwJB',
      startRating: 'https://codepen.io/torbencolding/pen/MobzGj',
      animatedCalendar: 'https://codepen.io/torbencolding/pen/GvvMvr',
      animatedSwitch: 'https://codepen.io/torbencolding/pen/oeezaO',
      animatedGradient: 'https://codepen.io/torbencolding/pen/xqgqMG',
      gridDemo03: 'https://codepen.io/torbencolding/pen/XGeMbz',
      screenAspectRatios: 'https://codepen.io/torbencolding/pen/ywjMrL',
      perioticTableHtml: 'https://codepen.io/torbencolding/pen/rzZMjY',
      animatedBalls: 'https://codepen.io/torbencolding/pen/BwQKbJ',
      emVsRemUnits: 'https://codepen.io/torbencolding/pen/MYpMpx',
      flexboxHorizontalAlignment: 'https://codepen.io/torbencolding/pen/503d14796dc16e97b8c604e723806091',
      codepen: 'https://codepen.io/torbencolding/'
    };
  }


  render() {

    return (

      <section className="App-section-outer codepen">
      
      <section className="App-section-inner">
        <h2><img src={CodepenLogo} alt="Codepen logo" className="brands"/></h2>

        <div className="text-columns-2">
            <p>Aliquam tristique velit lectus, id cursus massa egestas id. Fusce a posuere erat. Ut euismod nec orci ut egestas. Curabitur ligula eros, vulputate sit amet quam quis, dictum viverra lectus.</p>
            <p>Aliquam tristique velit lectus, id cursus massa egestas id. Fusce a posuere erat. Ut euismod nec orci ut egestas. Curabitur ligula eros, vulputate sit amet quam quis, dictum viverra lectus.</p>
        </div>
      </section>


  <div className="css-grid">
          <div id="css-grid__item1" className="css-grid__child"><a href={this.state.animatedClock} target="_blank" data-tag="#Animation" rel="noopener noreferrer"><video className="animated-gif" autoPlay loop><source src={CssAnimatedClock} type="video/mp4" /></video></a></div>
          <div id="css-grid__item2" className="css-grid__child"><a href={this.state.animatedBeer} target="_blank" data-tag="#Animation" rel="noopener noreferrer">CSS Animated beer</a></div>
          <div id="css-grid__item3" className="css-grid__child"><a href={this.state.flexboxAutoMargin} target="_blank" data-tag="#CSSFlexbox" rel="noopener noreferrer">CSS Flexbox and auto margin</a></div>
          <div id="css-grid__item4" className="css-grid__child"><a href={this.state.cssGridlayouts} target="_blank" data-tag="#CSSGrid" rel="noopener noreferrer">CSS Grid Layout</a></div>
          <div id="css-grid__item5" className="css-grid__child"><a href={this.state.startRating} target="_blank" data-tag="#CheckboxHack" rel="noopener noreferrer">CSS Star Rating</a></div>
          <div id="css-grid__item6" className="css-grid__child"><a href={this.state.animatedSwitch} target="_blank" data-tag="#Animation" rel="noopener noreferrer">CSS Animated Switch</a></div>
          <div id="css-grid__item7" className="css-grid__child"><a href={this.state.animatedSvgLogo} target="_blank" data-tag="#Animation" rel="noopener noreferrer">Animated SVG logo</a></div>
          <div id="css-grid__item8" className="css-grid__child"><a href={this.state.animatedCalendar} target="_blank" data-tag="#Animation" rel="noopener noreferrer">CSS Animated Calendar</a></div>
          <div id="css-grid__item9" className="css-grid__child"><a href={this.state.animatedGradient} target="_blank" data-tag="#Animation" rel="noopener noreferrer">CSS Animated Gradient</a></div>
          <div id="css-grid__item10" className="css-grid__child"><a href={this.state.gridDemo03} target="_blank" data-tag="#CSSGrid" rel="noopener noreferrer">CSS Grid Layout</a></div>
          <div id="css-grid__item11" className="css-grid__child"><a href={this.state.perioticTableHtml} target="_blank" data-tag="#CSSGrid" rel="noopener noreferrer">HTML Periodic Table</a></div>
          <div id="css-grid__item12" className="css-grid__child"><a href={this.state.flexboxAlignment} target="_blank" data-tag="#CSSFlexbox" rel="noopener noreferrer">Flexbox Alignment</a></div>
          <div id="css-grid__item13" className="css-grid__child"><a href={this.state.responsivePagination} target="_blank" data-tag="#ResponsiveWebDesign" rel="noopener noreferrer">Responsive pagination</a></div>
          <div id="css-grid__item14" className="css-grid__child"><a href={this.state.animatedSvg} target="_blank" data-tag="#Animation" rel="noopener noreferrer">Animated SVG</a></div>
          <div id="css-grid__item15" className="css-grid__child"><a href={this.state.responsiveFilmstrip} target="_blank" data-tag="#ResponsiveWebDesign" rel="noopener noreferrer">Responsive filmstrip</a></div>
          <div id="css-grid__item16" className="css-grid__child"><a href={this.state.animatedClock} target="_blank" data-tag="#Animation" rel="noopener noreferrer">Animated Clock</a></div>
          <div id="css-grid__item17" className="css-grid__child"><a href={this.state.screenAspectRatios} target="_blank" data-tag="#CSSCalc" rel="noopener noreferrer">Screen Aspect Ratios</a></div>
          <div id="css-grid__item18" className="css-grid__child"><a href={this.state.CssAnimatedBalls} target="_blank" data-tag="#CSSPseudoSelectors" rel="noopener noreferrer"><video className="animated-gif" autoPlay loop><source src={CssAnimatedBalls} type="video/mp4" /></video></a></div>
          <div id="css-grid__item19" className="css-grid__child"><a href={this.state.cssTabset} target="_blank" data-tag="#CheckboxHack" rel="noopener noreferrer">CSS Tabset</a></div>
          <div id="css-grid__item20" className="css-grid__child"><a href={this.state.conditionalStyling} target="_blank" data-tag="#Animation" rel="noopener noreferrer">Conditional Styling</a></div>
      </div>


      <a href={this.state.codepen} target="_blank" className="btn-cta" rel="noopener noreferrer">Visit my Codepen profile</a>
      
      </section>  

        
    );
  }
}

export default BlockCodepen;