import React, { Component } from 'react';
import './GridGallery.css';




class GridGallery extends Component {
  render() {


    return (


      <div className="grid col-50-50">
        <div>
        <div className="text-block">
                <span>I am a</span> 
                <span>developer &</span>
                <span>teacher</span> 
                <span>who has a passion for</span> 
                <span>code</span> 
                <span>and design.</span>
            </div>
        </div>
        <div>
        <div className="nodes">  
           {/*}  <h2>Notes</h2> */}
              <ul>
                  <li>
                    <time className="date" dateTime="2017-07-08">April 3, 2017</time>
                    <h3>Brand new website based on React and Firebase DB</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                  </li>   
                  <li>
                    <time className="date" dateTime="2017-05-24">March 24, 2019</time>
                    <h3>JavaScript reading list for developers</h3>
                    <p>Felis a consequat interdum. Nulla congue risus at scelerisque faucibus.   </p>
                  </li> 
                  <li>
                    <time className="date" dateTime="2017-04-16">February 16, 2019</time>
                    <h3>This is yet another heading</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.  </p>
                  </li> 
              </ul>
            </div>  
        </div>
      </div>


  
       

    );
  }
}

export default GridGallery;