import React, { Component } from 'react';
import firebase from './Firebase.js';
import {Link} from '@reach/router';
import './Demo.css';




class Demo2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentItem: '',
      content: '', ////////////////////////////////////////////////////
      username: '',
      items: []
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    
  }
  
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const itemsRef = firebase.database().ref('items'); // database reference

    // Submit fields
    const item = {  
      title: this.state.currentItem,
      user: this.state.username,
      content: this.state.content /////////////////////////////////////////////////
    }
    itemsRef.push(item);

    // Setting state for fields (empty fields)
    this.setState({   
      currentItem: '',
      username: '',
      content: '' ////////////////////////////////////////////////
    });
  }

  componentDidMount() {
    const itemsRef = firebase.database().ref('items'); // database reference
    itemsRef.on('value', (snapshot) => {
      let items = snapshot.val();
      let newState = [];

      // Update fields
      for (let item in items) {
        newState.push({
          id: item,
          title: items[item].title,
          user: items[item].user,
          content: items[item].content ////////////////////////////////////////////////
        });
      }
      this.setState({
        items: newState
      });
    });
  }
  
  // Delete content
  removeItem(itemId) {
    const itemRef = firebase.database().ref(`/items/${itemId}`); // database reference
    itemRef.remove();
  }




  render() {
    return (
      <div className='app'>
        <header>
            <div className="wrapper">
              <h1>Fun Food Friends</h1>
                             
            </div>
        </header>
        <div className='container'>
          <section className='add-item'>
                <form onSubmit={this.handleSubmit}>
                  <input type="text" name="username" placeholder="What's your name?" onChange={this.handleChange} value={this.state.username} />
                  <input type="text" name="currentItem" placeholder="What are you bringing?" onChange={this.handleChange} value={this.state.currentItem} />
                 <textarea id="editor" rows="4" cols="50" name="content" placeholder="Content here..." onChange={this.handleChange} value={this.state.content}></textarea> {/* /////////////////////////// */}
               {/*    <div className="textarea-editable" name="content" contenteditable="true" onChange={this.handleChange} value={this.state.content}></div>*/}
              {/*  <Wysiwyg name="content" onChange={this.handleChange} value={this.state.content} />*/}
                  <button>Add Item</button>
                </form>
          </section>
          <section className='display-item'>
              <div className="wrapper">
                <ul>
                  {this.state.items.map((item) => {
                    return (
                      <li key={item.id}>
                        <h3><Link to="/">{item.title}</Link></h3>
                        <p>{item.content}</p> {/* /////////////////////////// */}
                        <p>brought by: {item.user}
                          <button onClick={() => this.removeItem(item.id)}>Remove Item</button>
                        </p>
                      </li>
                    )
                  })}
                </ul>
              </div>
          </section>
        </div>
      </div>
    );
  }
  
}


export default Demo2;