import React, { Component } from 'react';
import {Link} from '@reach/router';
import './Welcome.css';


class Welcome extends Component {
  
  
  render(props) {

    const {userName, logoutUser} = this.props;


    return (
        <div className="welcome">

          {/* the prop has to match the prop where the component is used - eg app.js */}
          <span>Welcome {userName}</span>, {' '} 
          <Link to="/" onClick={e => logoutUser(e)} >Logout</Link>

        </div>
        
    );
  }
}

export default Welcome;