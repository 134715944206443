import React, { Component } from 'react';
import AppLogo from './AppLogo.js';
import AppNavigation from './AppNavigation.js';


class AppHeader extends Component {


  render(props) {

    const {userName} = this.props;

    return (
      <header className="App-header">
       
        <AppLogo />
        <AppNavigation user={userName} logoutUser={this.logoutUser} />  

      </header>
    );
  }
}

export default AppHeader;




