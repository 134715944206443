import React, { Component } from 'react';
import {Link} from '@reach/router';
import './AppLogo.css';
import AppLogoSymbol from './AppLogo.svg';


class AppLogo extends Component {
  render() {
    return (
       
       <figure className="App-logo list-view">
        <div className="App-logo__symbol">
          <Link to="/">
            <img src={AppLogoSymbol} alt="logo" />
          </Link>
        </div>
      <figcaption className="App-logo__text"><Link to="/">torbencolding.dk</Link></figcaption>  
    </figure>

    );
  }
}

export default AppLogo;