import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import {Link} from '@reach/router';
import CssAnimatedClock from './codepen-thumbs/css-animated-clock.mp4';

class PageAbout extends Component {
  render() {

 //   const {user} = this.props;

    const styles = {
        backgroundColor: 'none'
    }

    return (
        <main className="App-content" style={styles}>  

            <Helmet>
                {/* Page info */}
                <title>Torben Colding | About</title>
                <meta name="description" content="About me..." />

                {/* Social Media */}  
                <meta property="og:locale" content="da_DK" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Torben Colding | Personal Website" />
                <meta property="og:url" content="https://torbencolding.dk" />
                <meta property="og:site_name" content="Torben Colding" />
                <meta property="og:description" content="..." />
                <meta property="og:image" content="https://torbencolding.dk/img/x.jpg" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Login | Torben Colding" />
                <body className="page-about" />
            </Helmet> 
            
            <h1>About</h1>
            
            <section className="App-section-outer">
              <div className="App-section-inner text-columns-2">
                
                <p>Hello and welcome to my personal website. My name is Torben. I'm a Danish web developer and teacher who lives in Denmark.</p>

                <p>In 2007 I started as a professional web developer and have been involved in al sorts of web projects. I've even taken part in creating a small CMS, which I spent almost a year co-developing.</p>

                <p>Since 2013, I have been teaching web development in various Danish educational programs such as Webintegrator and Mediegrafiker.</p>

                <p>At the moment I'm teaching frontend development at AspIT, which is a educational program for students with ASF.</p>

               
              </div>
            </section>
        </main>
    );
  }
}

export default PageAbout;
