import React, { Component } from 'react';
import FormError from './FormError.js';
import firebase from './Firebase.js';


class Register extends Component {
    constructor(props){
        super(props);

        // Capture values from form fields
        this.state = {
            displayName: '',
            email: '',
            passOne: '',
            passTwo: '',
            errorMessage: null
        }

        // Bind 'this' to make it work with regular functions (handleChange())
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const itemName = e.target.name;
        const itemValue = e.target.value;

        this.setState({[itemName]: itemValue}, () => {
            if(this.state.passOne !== this.state.passTwo){
                this.setState({errorMessage: 'Passwords do not match!'});
            } else {
                this.setState({errorMessage: null});
            }
        });
    }

    handleSubmit(e){
        var registrationInfo = {
            displayName: this.state.displayName,
            email: this.state.email,
            password: this.state.passOne
        }
        e.preventDefault();

        firebase.auth().createUserWithEmailAndPassword(
           registrationInfo.email,
           registrationInfo.password 
        )
        .then(() => {
         this.props.registerUser(registrationInfo.displayName);
        })
        .catch(error => {
            if(error.message !== null){
                this.setState({errorMessage: error.message});
            } else {
                this.setState({errorMessage: null});
            }
        });
    }


  render() {

  //  const {user} = this.props;


    return (
       
        <form onSubmit={this.handleSubmit}>   
                {this.state.errorMessage !== null ? (
                    <FormError theMessage={this.state.errorMessage}/>
                ) : null}
            <div className="field-group">
                <label htmlFor="displayName" className="label">Display Name:</label>
                <div className="field">
                    <input name="displayName" id="displayName"  type="text"  value={this.state.displayName} onChange={this.handleChange} placeholder="Display name" required />
                </div>
            </div>

            <div className="field-group">
                <label htmlFor="email" className="label">Email:</label>
                <div className="field">
                    <input name="email" id="email" type="email" spellCheck="false"  value={this.state.email} onChange={this.handleChange} placeholder="Email Address" required /> 
                </div>
            </div>

            <div className="field-group">
                <label htmlFor="passOne" className="label">Password:</label>
                <div className="field">
                    <input name="passOne" id="passOne"  type="password"  value={this.state.passOne} onChange={this.handleChange} placeholder="Password" required />
                </div>
            </div>

            <div className="field-group">
                <label htmlFor="passTwo" className="label">Repeat password:</label>
                <div className="field">
                    <input name="passTwo" id="passTwo"  type="password"  value={this.state.passTwo} onChange={this.handleChange} placeholder="Repeat password" required />
                </div>
            </div>

            <div className="field-group">
                <div className="field">
                    <button>Register</button>
                </div>
            </div>
        </form>
        
    );
  }
}

export default Register;