import React, { Component } from 'react';


class AppFooter extends Component {
  render() {
    return (
        <footer className="App-footer">
           Footer here...
      </footer>
    );
  }
}

export default AppFooter;
