import React, { Component } from 'react';
import axios from 'axios';
import {Helmet} from 'react-helmet';
import './PageContact.css';

//const API_PATH = 'http://localhost:3000/torben-colding/api/contact/index.php';
const API_PATH = 'https://react.torbencolding.dk/api/contact/index.php';

class PageContact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fname: '',
      lname: '',
      email: '',
      message: '',
      mailSent: false,
      error: null
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    console.log(this.state);

    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: this.state
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent
        });
      })
      .catch(error => this.setState({ error: error.message }));
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
    
  }
  

  render() {
    
    return (
        <main className="App-content">    

            <Helmet>
                {/* Page info */}
                <title>Torben Colding | Contact</title>
                <meta name="description" content="My contact info..." />

                {/* Social Media */}  
                <meta property="og:locale" content="da_DK" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Torben Colding | Personal Website" />
                <meta property="og:url" content="https://torbencolding.dk" />
                <meta property="og:site_name" content="Torben Colding" />
                <meta property="og:description" content="..." />
                <meta property="og:image" content="https://torbencolding.dk/img/x.jpg" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Login | Torben Colding" />
                <body className="page-contact" />
            </Helmet>    

            <section className="App-section-outer">
              <div className="App-section-inner">

                <h1>Contact</h1>
                <p>Hvis du af uforklarlige årsager har lyst til at kontakte mig, kan du gøre det via denne kontaktformular. Alternaltivt kan du også skrive til mail@torbencolding.dk </p>   
                
              
                <form className="contact-form" onSubmit={this.handleSubmit}>

                
                  <label>First Name</label>
                  <input type="text" id="fname" name="fname" placeholder="Your first name"
                    value={this.state.fname}
                    onChange={this.handleChange}
                  />


                  <label>Last Name</label>
                  <input type="text" id="lname" name="lname" placeholder="Your last name"
                    value={this.state.lname}
                    onChange={this.handleChange}
                  />


                  <label>Email</label>
                  <input type="email" id="email" name="email" placeholder="Your email"
                    value={this.state.email}
                    onChange={this.handleChange}
                  />


                  <label>Message</label>
                  <textarea id="message" name="message" placeholder="Your message"
                    onChange={this.handleChange}
                    value={this.state.message}
                  ></textarea>

                  <input type="submit" name="submit" value="Submit" />


{/*}
                  <div className="field-group">
                    <div className="field">
                      <input name="meetingName" id="meetingName"  type="text"  value={this.state.meetingName} onChange={this.handleChange} placeholder="Meeting name" required />
                    </div>
                  </div>

                  <div className="field-group">
                    <div className="field">
                      <input name="meetingName" id="meetingName"  type="text"  value={this.state.meetingName} onChange={this.handleChange} placeholder="Meeting name" required />
                    </div>
                  </div>

                  <div className="field-group">
                    <div className="field">
                      <input name="meetingName" id="meetingName"  type="text"  value={this.state.meetingName} onChange={this.handleChange} placeholder="Meeting name" required />
                    </div>
                  </div>

                  <div className="field-group">
                    <div className="field">
                      <input name="meetingName" id="meetingName"  type="text"  value={this.state.meetingName} onChange={this.handleChange} placeholder="Meeting name" required />
                    </div>
                  </div>
    */}



                    {this.state.mailSent &&
                      <div>Thank you for contacting us.</div>
                    }

                    {this.state.error  &&
                      <div className="error">Sorry we had some problems.</div>
                    }

                 

                </form>
              

              </div>
            </section>
        </main>
    );
  }
}

export default PageContact;
