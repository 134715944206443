import React, { Component } from 'react';
import {Helmet} from 'react-helmet';


class PageWork extends Component {
  render() {

 //   const {user} = this.props;

    const styles = {
        backgroundColor: 'none'
    }

    return (
       
            <main className="App-content" style={styles}> 
                <Helmet>
                    {/* Page info */}
                    <title>Torben Colding | Work</title>
                    <meta name="description" content="My work..." />

                    {/* Social Media */}  
                    <meta property="og:locale" content="da_DK" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Torben Colding | Personal Website" />
                    <meta property="og:url" content="https://torbencolding.dk" />
                    <meta property="og:site_name" content="Torben Colding" />
                    <meta property="og:description" content="..." />
                    <meta property="og:image" content="https://torbencolding.dk/img/x.jpg" />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content="Login | Torben Colding" />
                    <body className="page-work" />
                </Helmet> 
         
                <h1>Work</h1>
            </main>
       
    );
  }
}

export default PageWork;
