import React, { Component } from 'react';
import {Link} from '@reach/router';
import './Welcome.css';


class PageResources extends Component {

      
  render(props) {


    const pages = [
        {id: 1, name: 'W3', url: 'https://w3.org'},
        {id: 2, name: 'MDN Web Docs', url: 'https://developer.mozilla.org/en-US/'}, 
        {id: 3, name: 'W3Schools', url: 'https://www.w3schools.com/'},
        {id: 4, name: 'CSS-Tricks', url: 'https://css-tricks.com'},
        {id: 5, name: 'Stack Overflow', url: 'https://stackoverflow.com'},
        {id: 6, name: 'Dev.to', url: 'https://dev.to/'}, 
        {id: 7, name: 'Scotch.io', url: 'https://scotch.io'}, 
        {id: 8, name: 'Alligator.io', url: 'https://alligator.io/'},
        {id: 9, name: 'React', url: 'https:reactjs.org'},
        {id: 10, name: 'Create React App', url: 'https://facebook.github.io/create-react-app/'},
        {id: 9, name: 'Babel', url: 'https://babeljs.io/'}
    ];
   
    // Create menu items 
    const developmentLinks = pages.map((page) =>       
        <li key={page.id} className="">
            <a href={page.url} target="_blank" rel="noopener noreferrer">{page.name}</a>
        </li>
    );


    return (
        <section className="App-section-outer">
                <div className="App-section-inner">
                    <div className="">
                        <ul>
                            {developmentLinks}
                        </ul>
                    </div>
                </div>
        </section>
    );
  }
}

export default PageResources;