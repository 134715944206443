import React, { Component } from 'react';
import firebase from './Firebase.js';



class BlogPostList extends Component {

    constructor(props){
        super(props);
        this.deleteBlogPost = this.deleteBlogPost.bind(this);
    }
 
    deleteBlogPost = (e, whichBlogPost) => {
        e.preventDefault();
        const ref = firebase
        .database()
        .ref(`blogposts/${this.props.userID}/${whichBlogPost}`)
        ref.remove();
    }

  render(props) {

    const {blogPosts} = this.props;
    const myBlogPosts = blogPosts.map(item => {

       return(

            <li className="meeting_item" key={item.blogPostID}>
                {item.blogPostName} 
                <span onClick={(e) => this.deleteBlogPost(e, item.blogPostID)}> X </span>
            </li>
        );
    });

    return (
        <ul className="meeting-list">
            {myBlogPosts}
        </ul> 
    );
  }
}

export default BlogPostList;