import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import GridGallery from './GridGallery.js';
import BlockSkills from './BlockSkills.js';
import BlockCodepen from './BlockCodepen.js';
import {Link} from '@reach/router';


class PageHome extends Component {

  render() {

    const {user} = this.props;

    const styles = {
        backgroundColor: 'none'
    }

    return (
        <main className="App-content home" style={styles}>


          <Helmet>
            {/* Page info */}
            <title>Torben Colding | Home</title>
            <meta name="description" content="login..." />

            {/* Social Media */}  
            <meta property="og:locale" content="da_DK" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Torben Colding | Personal Website" />
            <meta property="og:url" content="https://torbencolding.dk" />
            <meta property="og:site_name" content="Torben Colding" />
            <meta property="og:description" content="..." />
            <meta property="og:image" content="https://torbencolding.dk/img/x.jpg" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content="Login | Torben Colding" />
            <body className="page-home" />
            </Helmet>

        

          <section className="App-section-outer bg-hero"><div className="App-section-inner"><GridGallery /></div></section>
          
          <section className="App-section-outer"><BlockCodepen /></section>
          
          <section className="App-section-outer color-milky" >
            <div className="App-section-inner">
            <h2 className="App-section__title">About me</h2>
            <div className="text-columns-2">
              <p>Cras pharetra arcu luctus magna elementum, et tempor justo imperdiet. Donec viverra est sed maximus semper. Integer lobortis rutrum neque. Integer consequat, magna sed pharetra imperdiet, purus arcu volutpat diam, nec venenatis nibh dui eget mauris.</p>
              <p>Duis commodo nulla a mauris luctus consequat sit amet ut ligula. Fusce et convallis nisi. Nunc quis est eget erat mollis scelerisque. Pellentesque volutpat erat libero, a congue quam suscipit id.</p>
              <p>Proin urna mauris, finibus ac tincidunt quis, pretium eu nisl. In hac habitasse platea dictumst. Praesent hendrerit mollis diam id tempus. Nulla facilisi. Ut arcu risus, aliquam in justo in, tempus molestie orci.</p>
              <Link to="/About">About section</Link>
              </div>
            </div>
          </section> 
          
          <section className="App-section-outer bg-dark"><div className="App-section-inner"><BlockSkills /></div></section>

          <section className="App-section-outer">
            <div className="App-section-inner">

              {/* If no user exists */}
              {user === null && (

                <span>
                  <a href="/register" className="btn">Register</a> 
                  <a href="/login" className="btn">Login</a>
                </span>
              )}

              {/* If user exists */}
              {user && (
                <a href="/meeting" className="btn">Meeting</a>
              )}

            </div>
          </section>  

          <section className="App-section-outer">
            <div className="App-section-inner">
              <h2>Work</h2>
              <div className="text-columns-2">
                <p>...</p>
                <Link to="/Work">Work section</Link>
              </div>
            </div>
          </section> 

          
      </main>
    );
  }
}

export default PageHome;


